export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const LOGIN_USER_FAIL = 'LOGIN_USER_FAIL';
export const LOGIN_USER = 'LOGIN_USER';
export const LOGOUT_USER = 'LOGOUT_USER';
export const LOGIN_FACEBOOK = 'LOGIN_FACEBOOK';


export const PROVIDER_LIST = 'PROVIDER_LIST';
export const MEMBER_FORM = 'MEMBER_FORM';
export const MEMBER_FORM_READY = 'MEMBER_FORM_READY';
export const MEMBER_FORM_FAIL = 'MEMBER_FORM_FAIL';
export const UPDATE_PATIENT = 'UPDATE_PATIENT';
export const UPDATE_PATIENT_FAIL = 'UPDATE_PATIENT_FAIL';
export const MAKE_RESERVATION = 'MAKE_RESERVATION';
export const MAKE_RESERVATION_READY = 'MAKE_RESERVATION_READY';
export const MAKE_RESERVATION_DATA = 'MAKE_RESERVATION_DATA';
export const MAKE_RESERVATION_FAIL = 'MAKE_RESERVATION_FAIL';
export const ADD_PATIENT = 'ADD_PATIENT';
export const ADD_PATIENT_FAIL = 'ADD_PATIENT_FAIL';
export const ADD_RELATIVE = 'ADD_RELATIVE';
export const ADD_RELATIVE_FAIL = 'ADD_RELATIVE_FAIL';
export const SET_SINGLE_PATIENT = 'SET_SINGLE_PATIENT';
export const SET_EMPTY_FORM = 'SET_EMPTY_FORM';
export const UPDATE_PATIENT_INFO = 'UPDATE_PATIENT_INFO';
export const UPDATE_PATIENT_INFO_FAIL = 'UPDATE_PATIENT_INFO_FAIL';
export const UPDATE_USER_INFO_FAIL = ' UPDATE_USER_INFO_FAIL';
export const UPDATE_USER_INFO = 'UPDATE_USER_INFO';
export const NEW_PATIENT_INFO = 'NEW_PATIENT_INFO';
export const NEW_PATIENT_INFO_FAIL = 'NEW_PATIENT_INFO_FAIL';
export const NEW_PATIENT_CLEAR = 'NEW_PATIENT_CLEAR';
export const ADD_NEW_COMMENT = 'ADD_NEW_COMMENT';
export const ADD_NEW_COMMENT_FAIL = 'ADD_NEW_COMMENT_FAIL';
export const UPDATE_PASSWORD_DATA = 'UPDATE_PASSWORD_DATA';
export const UPDATE_PASSWORD_FAIL = 'UPDATE_PASSWORD_FAIL';


export const PROVIDER_INFO = 'PROVIDER_INFO';
export const PROVIDER_INFO_READY = 'PROVIDER_INFO_READY';
export const SEND_FILTER_DATA= 'SEND_FILTER_DATA';

export const LIST_PROVIDERS = 'LIST_PROVIDERS';
export const LIST_PROVIDERS_SUCCESS = 'LIST_PROVIDERS_SUCCESS';
export const LIST_PROVIDERS_FAIL = 'LIST_PROVIDERS_FAIL';

export const GET_FILTER_PROVIDERS= 'GET_FILTER_PROVIDERS';
export const FILTER_PROVIDERS = 'FILTER_PROVIDERS';
export const FILTER_PROVIDERS_FAIL= 'FILTER_PROVIDERS_FAIL';

export const GET_SERVICES_READY= 'GET_SERVICES_READY';
export const GET_SERVICES= 'GET_SERVICES_READY';
export const GET_SERVICES_FAIL= 'GET_SERVICES_FAIL';


export const COMPANY_DETAIL = 'COMPANY_DETAIL';
export const COMPANY_DETAIL_READY = 'COMPANY_DETAIL_READY';
export const GET_COMMENT = 'GET_COMMENT';
export const GET_COMMENT_READY = 'GET_COMMENT_READY';
export const STATUS_PENDING = 'STATUS_PENDING';
export const STATUS_SUCCESS = 'STATUS_SUCCESS';
export const STATUS_FAIL = 'STATUS_FAIL';
export const GET_STATUS = 'GET_STATUS';
export const CAROUSEL_PROVIDER = 'CAROUSEL_PROVIDER';
export const CAROUSEL_PROVIDER_READY = 'CAROUSEL_PROVIDER_READY';
export const RESERVATION_DATA_LOADING = 'RESERVATION_DATA_LOADING';
export const RESERVATION_DATA = 'RESERVATION_DATA';
export const GET_PATIENTS = 'GET_PATIENTS';
export const COMMENT_CAROUSEL = 'COMMENT_CAROUSEL';
export const COMMENT_CAROUSEL_READY = 'COMMENT_CAROUSEL_READY';
export const REGISTER_USER = 'REGISTER_USER';
export const REGISTER_USER_FAIL = 'REGISTER_USER_FAIL';
export const REGISTER_USER_REQUEST = 'REGISTER_USER_REQUEST';
export const VERIFICATION_CODE = 'VERIFICATION_CODE';
export const SET_VERIFICATION_CODE = 'SET_VERIFICATION_CODE';
export const VERIFICATION_CODE_FAIL = 'VERIFICATION_CODE_FAIL';
export const CODE_SUBMIT = 'CODE_SUBMIT';
export const SET_CODE_SUBMIT = 'SET_CODE_SUBMIT';
export const CODE_SUBMIT_FAIL = 'CODE_SUBMIT_FAIL';
export const APPOINTMENT_SERVICE = 'APPOINTMENT_SERVICE';
export const APPOINTMENT_SERVICE_READY = 'APPOINTMENT_SERVICE_READY';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const CHANGE_PASSWORD_READY = 'CHANGE_PASSWORD_READY';
export const PASSWORD_RESET_SUCCESS = 'PASSWORD_RESET_SUCCESS';
export const PASSWORD_RESET_FAIL = 'PASSWORD_RESET_FAIL';
export const INFO_CARD = 'INFO_CARD';
export const INFO_CARD_READY = 'INFO_CARD_READY';
export const RESET_COMMENT_BOX = 'RESET_COMMENT_BOX';
export const RESET_PATIENT_INFO = 'RESET_PATIENT_INFO';
export const UPDATE_PROVIDERS_PRICE = 'UPDATE_PROVIDERS_PRICE';
