export const GET_APPOINTMENT_READY = 'GET_APPOINTMENT_READY';
export const GET_APPOINTMENT_SUCCESS = 'GET_APPOINTMENT_SUCCESS';
export const NEW_APPOINTMENT_BROADCAST = 'NEW_APPOINTMENT_BROADCAST';
export const GET_APPOINTMENT_FAIL = 'GET_APPOINTMENT_FAIL';
export const SELECT_APPOINTMENT = 'SELECT_APPOINTMENT';
/************************************************/
//Provider profile page
//get
export const PROVIDER_PROFILE_READY = 'PROVIDER_PROFILE_READY';
export const PROVIDER_PROFILE = 'PROVIDER_PROFILE';
//put
export const UPDATE_PROVIDER_PROFILE = 'UPDATE_PROVIDER_PROFILE';
export const UPDATE_PROVIDER_PROFILE_FAIL = 'UPDATE_PROVIDER_PROFILE_FAIL';

//stations
//get
export const STATION_INFORMATION = 'STATION_INFORMATION';
export const STATION_INFORMATION_READY = 'STATION_INFORMATION_READY';
//POST
export const ADD_NEW_STATION = 'ADD_NEW_STATION';
export const ADD_NEW_STATION_FAIL = 'ADD_NEW_STATION_FAIL';
export const RESET_STATION_BOX = 'RESET_STATION_BOX';
//put
export const UPDATE_STATION = 'UPDATE_STATION'
export const UPDATE_STATION_FAIL = 'UPDATE_STATION_FAIL'

export const APPOINTMENT_STATUS_READY ='APPOINTMENT_STATUS_READY';
export const ACCEPT_APPOINTMENT ='ACCEPT_APPOINTMENT';
export const ACCEPT_APPOINTMENT_FAIL ='ACCEPT_APPOINTMENT_FAIL';
export const REJECT_APPOINTMENT ='REJECT_APPOINTMENT';
export const REJECT_APPOINTMENT_FAIL ='REJECT_APPOINTMENT_FAIL';

export const SEND_FEEDBACK ='SEND_FEEDBACK';

//rate-comment
//get
export const COMMENT_LIST = 'COMMENT_LIST';
export const COMMENT_LIST_READY = 'COMMENT_LIST_READY';
// PRICE UPDATE
export const UPDATE_PRICE_FAIL = 'UPDATE_PRICE_FAIL';
export const UPDATE_PRICE = 'UPDATE_PRICE';
export const RESET_PRICE_COMPONENT = 'RESET_PRICE_COMPONENT';
