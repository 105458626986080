import Echo from 'laravel-echo';
import Pusher from "pusher-js";
import jwt_decode from 'jwt-decode';
import setAuthToken from './SetAuthToken';
import API from '../../api.js';

import { SET_CURRENT_USER, LOGIN_FACEBOOK, LOGIN_USER_FAIL, LOGIN_USER, LOGOUT_USER } from '../types';
import { NEW_APPOINTMENT_BROADCAST } from '../provider/types'

const options = {
  broadcaster: 'pusher',
  key: '0c7d27ebb25a8e2c0e41',
  cluster: 'eu',
  // forceTLS: true,
  //authEndpoint is your apiUrl + /broadcasting/auth
  authEndpoint: 'https://api.iyiles.com/broadcasting/auth',
  // As I'm using JWT tokens, I need to manually set up the headers.
  auth: {
    headers: {
      'Authorization': 'Bearer '+ localStorage.getItem('jwtToken'),
      'Accept': 'application/json'
    },
  },
};

var echo = null;

export const loginUser = userData => dispatch => {
  dispatch({ type: LOGIN_USER });
  API.post('login', userData)
  .then(res => {
    const token = res.data.access_token;
    localStorage.setItem('jwtToken', token);
    setAuthToken(token);
    const decoded = jwt_decode(token);
    dispatch({
      type: SET_CURRENT_USER,
      userRole: res.data.user_role,
      payload: decoded
    });
    console.log('action');
    if( res.data.user_role === "provider"){
      echo = new Echo(options);
      console.log('action role enter');
      console.log("App.User."+decoded.sub);
      echo.private("App.User."+decoded.sub).notification((data) => {
          console.log(data);
          dispatch({
              type: NEW_APPOINTMENT_BROADCAST,
              payload: data
          });
      });
    }
  })
  .catch(err =>{
    dispatch({
      type: LOGIN_USER_FAIL
    });
  })
}
export const loginWithFacebook = userData => dispatch => {
  dispatch({ type: LOGIN_USER });
  API.post('login/facebook', userData)
  .then(res => {
    const token = res.data.access_token;
    localStorage.setItem('jwtToken', token);
    setAuthToken(token);
    const decoded = jwt_decode(token);
    dispatch({
      type: SET_CURRENT_USER,
      userRole: res.data.user_role,
      payload: decoded
    });
  })
  .catch(err =>{
    dispatch({
      type: LOGIN_USER_FAIL
    });
  })
}



export const logoutUser = () => dispatch => {
  dispatch({ type: LOGOUT_USER });
  // if(echo !== null) {
  //   echo.disconnect()
  // }
}
