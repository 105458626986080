import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Script from 'react-load-script';
import store from './store';
import PrivateRoute from './services/PrivateRoute.js';
import setAuthToken from './services/Login/SetAuthToken';
import {Helmet} from 'react-helmet';
import ReactGA from 'react-ga';
import CookieConsent, { Cookies } from "react-cookie-consent";
import { YMInitializer } from 'react-yandex-metrika';
import './components/provider/Layout.css'
import ReactPixel from 'react-facebook-pixel';


setAuthToken();
const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;
const options = {
  autoConfig: true, // set pixel's autoConfig
  debug: false, // enable logs
};


class App extends Component {

  componentDidMount (){
        ReactPixel.init('895457530952001');
        ReactPixel.pageView();
        window.$crisp=[];
        window.CRISP_WEBSITE_ID="41223682-30b8-4640-b017-931206fabee4";
        ReactGA.initialize('UA-123177700-17');
        ReactGA.pageview(window.location.pathname+window.location.search);
      (function() {
        var d = document;
        var s = d.createElement("script");

      s.src = "https://client.crisp.chat/l.js";
      s.async = 1;
      d.getElementsByTagName("head")[0].appendChild(s);
    })();
  };

  constructor() {
      super();
      //Setting up global variable
      global.MyVar = 'https://api.iyiles.com/';
      this.handleScriptLoad = this.handleScriptLoad.bind(this);
    }
  handleScriptLoad() {
        /*global google*/
  console.log('handleScriptLoad');
  }

  render(){
    return(
      <Provider store={store}>
      <YMInitializer accounts={[57477220]} options={{clickmap:true, trackLinks:true, accurateTrackBounce:true, ecommerce:"dataLayer", webvisor: true}}  />
        <div className='App main-row'>
          <Script url="https://cdn.onesignal.com/sdks/OneSignalSDK.js" async="" onLoad={this.handleScriptLoad} />
          <Helmet>
          <script async src="https://www.googletagmanager.com/gtag/js?id=AW-604172263"></script>
          </Helmet>
          <Router>
            <React.Suspense fallback={loading()}>
              <Switch>
                <Route path="/" render={props => <PrivateRoute {...props} />} />
              </Switch>
            </React.Suspense>
          </Router>
        </div>
           <CookieConsent
            location="bottom"
            buttonText="Anladım"
            cookieName="cookieConsent"
            style={{ background: "#F68A25", fontSize:'12px', width:'100%' }}
            buttonStyle={{ color: "black", fontSize: "13px", backgroundColor:"#F68A25", paddingRight:'100%' }}
            expires={350} >
            Sitemizi kullanarak çerezlere (cookie) izin vermektesiniz. Detaylı bilgi için Gizlilik Sözleşmemizi inceleyebilirsiniz. {" "}
          </CookieConsent>
      </Provider>
    )
  }
}

export default App;
