// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Roboto:400,400i,500,500i,700,700i,900,900i&display=swap);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Open+Sans&display=swap);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Open+Sans:700&display=swap);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Open+Sans:600i&display=swap);"]);
// Module
exports.push([module.id, "\nbody {\n  margin: 0;\n  padding: 0;\n  font-family: -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\",\n    \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\",\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, \"Courier New\",\n    monospace;\n}\n@media (min-width: 1200px)\n<style>…</style>\n.container {\n    max-width: 1140px;\n}\n", ""]);
// Exports
module.exports = exports;
